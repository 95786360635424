import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { useAuthStore } from '@/stores/auth'
import type { SchoolResponseDto } from '@/assets/DTO/school/school.response.dto'
import { NabooError } from '@/assets/classes/Error'

export const useSchoolStore = defineStore('School', () => {
  // STORES
  const { isAdmin, isPerdir, isCpe, isTeacher } = storeToRefs(useAuthStore())

  // STATE
  const loading = ref(false)
  const hasError = ref(false)
  const errorMessage = ref('')
  const schoolList = ref<SchoolResponseDto[]>([])

  // ACTIONS
  /**
   * Fetch schools depending on the user role
   */
  async function fetchSchools() {
    if (isAdmin.value) {
      await fetchAllSchools()
    } else if (isPerdir.value || isCpe.value || isTeacher.value) {
      await fetchSchoolsForMe()
    } else {
      schoolList.value = [] as SchoolResponseDto[]
    }
    return Promise.resolve()
  }

  /**
   * Fetch all schools, only for admin
   */
  async function fetchAllSchools() {
    try {
      // Set flags
      loading.value = true
      hasError.value = false
      errorMessage.value = ''

      // Fetch data
      const res: SchoolResponseDto[] = await nabooApi.getAllSchools()
      // Set data
      if (res) schoolList.value = res
      return Promise.resolve()
    } catch (error) {
      hasError.value = true
      if (error instanceof NabooError) {
        errorMessage.value = error.message
      }
    } finally {
      loading.value = false
    }
  }

  /**
   * Fetch schools for the current user, usually for teachers
   */
  async function fetchSchoolsForMe() {
    try {
      // Set flags
      loading.value = true
      hasError.value = false
      errorMessage.value = ''

      // Fetch data
      const res: SchoolResponseDto[] = await nabooApi.getSchoolsForMe()

      // Set data
      if (res) schoolList.value = res
      return Promise.resolve()
    } catch (error) {
      hasError.value = true
      if (error instanceof NabooError) {
        errorMessage.value = error.message
      }
    } finally {
      loading.value = false
    }
  }

  // GETTERS (COMPUTED)
  const getSchoolById = computed(() => {
    return (id: number) => {
      return schoolList.value.find((school) => school.id === id)
    }
  })

  return { schoolList, fetchSchools, getSchoolById }
})
